.body-app-desk {
    background-color: #f9f9f9 !important;
}

.modal-content-desk {
    width: auto !important;
    height: auto !important;
    background-color: white !important;
}

.app-bg-home-cover {
    height: 200px !important;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    z-index: -1;
}

.app-categ-btn {
    width: auto !important;
    height: 40px !important;
    border-radius: 12px !important;
    border-color: #dddcdc !important;
    background-color: white !important;
    border-style: solid;
    border-width: 1px;
}
.app-categ-btn-susp {
    width: auto !important;
    height: 40px !important;
    border-radius: 12px !important;
    border-color: white !important;
    background-color: white !important;
    border-style: solid;
    border-width: 0px;
    
}
.box-top-info-company {
    min-width: 100% !important;
    min-height: auto !important;
    background-color: white;
    margin-top: 10% !important;
    z-index: 200;
    padding: 10px !important;
    position: relative;
    border-radius: 10px !important;


}
.box-top-info-company-fixes-top {
    min-width: 100% !important; 
    min-height: auto !important;
    height: auto !important;
    background-color: white;
    z-index: 200;
    margin: 0 !important;
    padding: 10px !important;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;


}
.box-apps-loja {
    display: flex;
    flex-direction: column;
    grid-column: 12 / span 2;
}

.box-loja {
    display: flex;
}

.img-brand-store {
    width: 160px;
    height: auto !important;
    margin-top: 20%;
}

.img-qrcode-app {
    width: 140px;
    height: auto !important;
}



.box-facade-down-mobile {
    background-color: white;
    height: auto !important;

    padding-left: 10% !important;
    padding-right: 10% !important;
    width: 100%;
    right: 0;
    left: 0;
}

.box-footer-desk {
    width: auto !important;

    height: auto !important;
    right: 0;
    left: 0;
    bottom: 0 !important;
    z-index: 200;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(35, 31, 32);
}

.box-sec-one {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.box-sec-two {
    width: auto !important;
    display: grid;
    grid-template-rows: max-content;
    column-gap: 1rem;

    grid-template-columns: 1.5rem repeat(12, 4.5rem) 1.5rem;
}

.box-sec-inner-one {
    display: flex !important;
    flex-direction: column !important;
    grid-column-end: span 3 !important;
}

.img_logo_footer {
    width: 140px !important;
}

.img_banner_promo {
    width: 1000px !important;
}

.box-sec-inner-one:nth-child(4n+1) {
    grid-column-start: 2 !important;
}

.box-footer-title {
    font-size: 1.15rem !important;
    line-height: 1.2 !important;
    color: rgb(255, 255, 255) !important;
    margin-bottom: 1rem !important;
}

.box-footer-ul {
    list-style: none !important;
    color: white !important;
}

.box-footer-ul-font {
    font-size: 0.9rem !important;
    line-height: 1.5 !important;

}

.box-footer-ul-font a {
    color: white !important;
}

.box-top-info-company-inner {
    background-color: white;
    height: auto !important;
    z-index: 200;
    padding: 10px !important;
    position: relative;
    border-radius: 10px !important;


}



.border-radius-10 {
    background-color: white !important;
    padding: 0.5px;
    border-radius: 10px !important;
}

.app-header-desk {
    /* border-bottom: 1px solid rgb(225, 219, 221);
   background-color: rgb(255, 255, 255);*/
    position: sticky;
    top: 0px;

    z-index: 100;
}

.app-box-ind {
    width: 24px !important;
}

.app-header-desk-a {
    margin-left: 20px !important;
    margin-right: 20px !important;
    /* width: 100%;
    display: grid;
    grid-template-rows: max-content;
    column-gap: 1rem;
    -webkit-box-pack: center;
    justify-content: center;
    grid-template-columns: minmax(1.5rem, 1fr) repeat(12, 4.5rem) minmax(1.5rem, 1fr);*/
}

.app-header-desk-e {
    display: flex;
    flex-direction: column;
    grid-column: 2 / span 12;
}

.app-header-desk-i {
    height: auto;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.app-header-desk-o {
    display: flex;
    margin-right: 5rem;
}

.app-header-desk-menu {
    cursor: pointer;
    margin-right: 1rem;
    position: relative;
}

img {
    pointer-events: none !important;
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
}

.btn_custom_menu {
    font-family: SourceSansPro-Bold;
    font-size: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

.btn_custom_logo {
    font-family: SourceSansPro-Bold;
    font-size: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

.fit-ret {
    width: 3rem;
}

.grid-options-menu {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.op-menu {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    color: rgb(98, 93, 94);
}

.font-size-xxs {
    font-size: 0.875rem;
    line-height: 1.2;
}


.op-menu:hover,
.op-menu[class*="active"] {
    color: rgb(200, 16, 46);
}

@media only screen and (max-width: 500px) {
    .box-apps-loja {
        grid-column: 4 / span 2;
    }

    .fit-ret {
        width: 0px;
    }

    .s_logo_sec {
        width: 153px;
        height: 26px;
    }

    .grid-options-menu {
        align-items: flex-start;
        flex-direction: column;
    }

    .op-menu {
        margin-top: 2rem;
    }
}