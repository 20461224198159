.container-top-float {
    position: relative;
    top: 450px !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
    background-color: white !important;
    background-color: #f9f9f9 !important;
    height: auto !important;
    width: 100% !important;
    z-index: 200 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

}

.img-promo-mchant {
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: auto !important;
    position: fixed;
}

::-webkit-scrollbar {
    width: 0.1px;
    height: 0.1px;
}


::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background: white;
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: none;
}

.react-drawer-drawer {
    width: 500px !important;
}

.footer-bg-cart-itens {
    z-index: 400 !important;

}

.footer-bg-cart-itens-mb {
    margin-bottom: 100px !important;
}

.box-cart-footer-align {
    position: fixed !important;
    width: 100% !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 300 !important;
    height: 64px !important;

    overflow-y: hidden;
    overflow-x: hidden;
}

.bx-w-one-card {
    width: 380px;
    height: 450px;

}

.bx-w {
    width: 260px !important;
    height: 450px !important;

}

.bx-w-img-one-card {
    width: auto !important;
    height: auto !important;

}

.bx-w-img {
    width: 200 !important;
    height: auto !important;

}

.bx-w-img-one-card-p {
    width: 200px !important;
    height: auto !important;

}

.bx-w-img-p {
    width: 200px !important;
    height: auto !important;

}

.mt-n {
    margin-top: -12px !important;
}

.mt-n-2 {
    margin-top: -14px !important;
}

.mt-n-hr {
    margin-top: -1px !important;
}

.box-cart-footer-align_with_progress {
    position: fixed !important;
    width: 100% !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    /*height: 90px !important; 105*/

    overflow-y: hidden;
    overflow-x: hidden;
}

.scrollbar-container {
    background-color: #ffffff !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 css-sourceMap-modules-importLoaders-1-localIdentName-animate__fadeIn___3bQIe {
    background-color: #ffffff !important;
}

.react-drawer-overlay {
    background-color: #ffffff !important;
}

.m-app-r {
    margin-right: 15px !important;
}

.m-b-110 {
    margin-bottom: 110px !important;
}

.bg-home-cover {
    height: 244px !important;
    margin-bottom: 10px !important;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    z-index: -1;
}

.bg-home-cover:nth-child(1) {
    box-shadow: 0 3px 3px rgb(0 0 0 / 0.2);

}

/*
.header-height-size-300 {
    height: 330px !important;
}
.header-height-size-200 {
    height: 20px !important;

}*/
.img-home-logo {
    width: 100px !important;
    height: 100px !important;
}

.text-title-partner {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.bg-box-detail-partner {
    bottom: 0;
    left: 0;
    padding: 15px 0;
    right: 0;
    background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, .83));
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .83)));
    background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .83));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#d4000000", GradientType=0);
}

.bg-top-menu-home-cover {
    height: 174px !important;
    background-color: #ffffff !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    position: absolute !important;
    width: 100% !important;
    z-index: 100 !important;
    top: 72px !important;
}

/*
@media only screen and (max-width: 360px) {

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background-color: white !important;
    }

    .bottom-nav {
        height: 45px !important;
    }

    .bottom-nav-item {
        padding-top: 5px !important;
    }

    .bg-top-menu-home-cover {
        height: 190px !important;
        top: 52px !important;
    }

    .bg-home-cover {
        margin-bottom: 25px !important;
    }

    .category-menus-grid {
        height: auto !important;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        height: 40px !important;
        margin-bottom: 0px !important;

    }

    .react-drawer-drawer {
        width: 100% !important;

    }

    .footer-bg-cart-itens {
        z-index: 0 !important;

    }

    .footer-bg-cart-itens-mb {
        margin-bottom: 0px !important;
    }

    .box-cart-footer-align {
        bottom: 0 !important;
        height: 54px !important;
    }

}*/


@media only screen and (min-width: 360px),
(min-width: 375px) {

    .bx-w-one-card {
        width: 395px;
        height: auto;

    }

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background-color: white !important;
    }

    .bottom-nav {
        height: 45px !important;
    }

    .bottom-nav-item {
        padding-top: 5px !important;
    }

    .bg-top-menu-home-cover {
        height: 190px !important;
        top: 52px !important;
    }

    .bg-home-cover {
        margin-bottom: 20px !important;
    }

    .react-drawer-drawer {
        width: 100% !important;

    }

    .footer-bg-cart-itens {
        z-index: 0 !important;

    }

    .footer-bg-cart-itens-mb {
        margin-bottom: 0px !important;
    }

    .box-cart-footer-align {
        bottom: 0 !important;
        height: 54px !important;
    }

}

/*
@media only screen and (width: 500px) {
    .bx-w-one-card {
        width: 435px !important;
        height: 450px !important;

    }

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background-color: white !important;
    }

    .bottom-nav {
        height: 45px !important;
    }

    .bottom-nav-item {
        padding-top: 5px !important;
    }

    .bg-top-menu-home-cover {
        height: 175px !important;
        top: 62px !important;
    }

    .bg-home-cover {
        margin-bottom: 15px !important;
    }

    .react-drawer-drawer {
        width: 100% !important;

    }

    .footer-bg-cart-itens {
        z-index: 0 !important;

    }

    .footer-bg-cart-itens-mb {
        margin-bottom: 0px !important;
    }

    .box-cart-footer-align {
        bottom: 0 !important;
        height: 54px !important;

    }
}*/
/*
@media only screen and (device-width: 414px) {
    .container-top-float {
        top: 450px !important;

    }

    .img-promo-mchant {
        padding-top: 40px;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;
        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 435px;
        height: auto;

    }

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background-color: white !important;
    }


    .bottom-nav {
        height: 45px !important;
    }

    .bottom-nav-item {
        padding-top: 5px !important;
    }

    .bg-top-menu-home-cover {
        height: 175px !important;
        top: 62px !important;
    }

    .bg-home-cover {
        margin-bottom: 15px !important;
    }

    .react-drawer-drawer {
        width: 100% !important;

    }

    .footer-bg-cart-itens {
        z-index: 0 !important;

    }

    .footer-bg-cart-itens-mb {
        margin-bottom: 0px !important;
    }

    .box-cart-footer-align {
        bottom: 0 !important;
        height: 54px !important;

    }
}
*/


@media only screen and (max-width: 820px) {

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background-color: white !important;
    }


    .bottom-nav {
        height: 45px !important;
    }

    .bottom-nav-item {
        padding-top: 5px !important;
    }

    .bg-top-menu-home-cover {
        height: 175px !important;
        top: 62px !important;
    }

    .bg-home-cover {
        margin-bottom: 15px !important;
    }

    .react-drawer-drawer {
        width: 100% !important;

    }

    .footer-bg-cart-itens {
        z-index: 0 !important;

    }

    .footer-bg-cart-itens-mb {
        margin-bottom: 0px !important;
    }

    .box-cart-footer-align {
        bottom: 0 !important;
        height: 54px !important;

    }
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
    background: rgba(0, 0, 0, 0.15) !important;
}

.scrollbar-container {
    background-color: #ffffff !important;
}

.react-drawer-drawer {
    z-index: 9999 !important;
    background: #fff !important;
}

/*.react-drawer-overlay {
    background-color: #ffffff !important;
}*/

.ps {
    background-color: #ffffff !important;
}

.react-drawer-overlay css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 css-sourceMap-modules-importLoaders-1-localIdentName-animate__fadeIn___3bQIe {
    background-color: #ffffff !important;
}
@media only screen and (device-width: 375px) {
    .container-top-float {
        top: 400px !important;

    }
    .img-promo-mchant {
        padding-top: 30px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
    }
}
@media only screen and (device-width: 360px) {
    .container-top-float {
        top: 450px !important;

    }

    .img-promo-mchant {
        padding-top: 40px;

        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;
        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 380px !important;
        height: auto !important;

    }


}

@media only screen and (device-width: 390px) {
    .container-top-float {
        top: 380px !important;

    }

    .img-promo-mchant {
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;
        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 410px !important;
        height: auto !important;

    }




}

@media only screen and (device-width: 393px) {
    .container-top-float {
        top: 450px !important;

    }

    .img-promo-mchant {
        padding-top: 40px;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;
        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 415px !important;
        height: auto !important;

    }



}

@media only screen and (device-width: 412px) {
    .container-top-float {
        top: 480px !important;

    }

    .img-promo-mchant {
        padding-top: 40px;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;

        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 435px !important;
        height: auto !important;

    }




}

@media only screen and (device-width: 414px) {
    .container-top-float {
        top: 400px !important;
        right: 0 !important;
        left: 0 !important;
    }

    .img-promo-mchant {
        padding-top: 20px;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: auto;
        display: block;
        min-width: auto !important;
        min-height: auto !important;

        max-width: 500px !important;
        max-height: auto !important;
        width: 500px !important;
        height: auto !important;
        float: left;
        clear: both;
    }

    .bx-w-one-card {
        width: 435px !important;
        height: auto !important;

    }


}

@media only screen and (device-width: 820px) {
    .container-top-float {
        top: 870px !important;

    }
    .img-promo-mchant {
        padding-top: 30px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        max-width: 100% !important;
        max-height: auto !important;
        width: 100% !important;
        height: auto !important;
    }
}