@import url('https://fonts.cdnfonts.com/css/bariol');
@import url('https://fonts.cdnfonts.com/css/gotham');

.font-bl-25-700h {
    font-family: 'Bariol', sans-serif;
    font-weight: 700;
    font-size: 25px;
}

.font-bl-13-700h {
    font-family: 'Bariol', sans-serif;
    font-weight: 700;
    font-size: 13px;
}

.font-bl-12-400h {
    font-family: 'Bariol', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.font-bl-11-400h {
    font-family: 'Bariol', sans-serif;
    font-weight: 400;
    font-size: 11px;
}

.font-bl-20-700h {
    font-family: 'Bariol', sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.font-bl-18-700h {
    font-family: 'Bariol', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.font-bl-20-400h {
    font-family: 'Bariol', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.font-bl-18-300h {
    font-family: 'Bariol', sans-serif;
    font-weight: 300;
    font-size: 18px;
}

.font-got-blk-24-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 24px;
}

.font-got-blk-20-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.font-got-blk-18-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.font-got-blk-16-300h {
    font-family: 'Gotham Black', sans-serif !important;
    font-weight: 200 !important;
    font-size: 16px !important;
}

.font-got-blk-16-400h {
    font-family: 'Gotham Black', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.font-got-blk-15-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.font-got-blk-14-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.font-got-blk-11-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 11px;
}

.font-got-blk-11-300h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 300;
    font-size: 11px;
}

.font-got-blk-12-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.font-got-blk-13-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 13px;
}

.font-got-lt-13-300h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 300;
    font-size: 13px;
}

.font-got-blk-14-400h {
    font-family: 'Gotham Black', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.font-got-lt-14-300h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 300;
    font-size: 14px;
}

.font-got-lt-16-400h {
    font-family: 'Gotham Light', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.font-got-lt-15-400h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.font-got-lt-14-400h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.font-got-lt-13-400h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 13px;
}

.font-got-lt-12-300h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 300;
    font-size: 12px;
}

.font-got-lt-11-300h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 300;
    font-size: 11px;
}

.font-got-lt-10-300h {
    font-family: 'Gotham Light', sans-serif;
    font-weight: 300;
    font-size: 10px;
}