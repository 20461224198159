
.ui-autocomplete-loading {
    background: url('https://www.aspsnippets.com/demos/img/loader2.gif') no-repeat;
    background-position: calc(100% - 15px) 50%;
}

.ui-autocomplete-input {
    color: #000000;
}

.ui-autocomplete {
    z-index: 10000000;
}

