html {
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    clear: both;
    overflow-x: hidden !important;

}

.round-top-left-right12 {
    width: 100% !important;
    left: 0;
    right: 0;
    background-color: white !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.mt-align-float {
    top: -35px !important;
    z-index: 200;
}

::-webkit-scrollbar {
    width: 0.1px;
    height: 0.1px;
}


::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background: white;
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: none;
}

.container-box {
    background-color: white !important;
    height: 100% !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0  !important;
    padding-bottom: 0 !important;
    position: absolute;
    
}

.container-box-inner {
    position: relative;
    margin-top: -10px !important;
    background-color: white !important;
}

.container-img-box {
    min-width: 500px !important;
    min-height: 500px !important;
    max-width: 100% !important;
    width: 100% !important;
    height: 800px !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0;
    display: inline-block;
    position: absolute;
    padding-top: 30px !important;

}

.container-top-float {
    min-width: 100% !important;
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;

    background-color: white !important;
    height: 100% !important;
    z-index: 200 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.bg-img-fixed {
    position: fixed !important;
}

.bx-card-promo-footer {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    background-color: rgb(106, 43, 43) !important;
}

.header-bar-top {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: white !important;
    height: 60px !important;
    width: 100% !important;
    z-index: 999 !important;

}

.modal-fullscreen-lg-down {
    width: 100% !important;
    max-width: none;
    margin: 0;
    left: 0;
    right: 0;
    height: 100% !important;
    top: 0;
}

.modal-content {
    width: 100% !important;
    height: 100% !important;
    background-color: white !important;
}

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

.bottom-bar-options {
    width: 100% !important;
    height: auto !important;
    position: absolute;
    margin: 0 !important;
    bottom: 0 !important;


    padding-top: 20px !important;
    padding-bottom: 10px !important;
    background-color: white !important;
    z-index: 200 !important;
}

.bottom-bar-partners {
    width: 100% !important;
    height: auto !important;
    position: fixed;
    margin: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;

    box-shadow: 0 3px 3px rgb(0 0 0 / 0.2), 0 -3px -3px rgb(0 0 0 / 0.2);
    background-color: white !important;
    z-index: 200 !important;
}

.mg-bottom-bar {
    margin-bottom: 60px !important;
}

.bg-gray-light {
    width: 100% !important;
    background-color: #f0f0f0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.img-card-list {
    height: auto !important;
    width: 100px !important;

}

.img-card-desc {
    height: 200px !important;
    width: 200px !important;

}

.mp-select-content {
    width: 100px !important;
    height: auto !important;
}

.mp-text-content {
    width: auto !important;
    height: auto !important;
}

.mdi-yellow {
    color: rgba(250, 196, 62, 0.9) !important;
}

.mdi-yellow.mdi-active {
    color: rgba(250, 196, 62, 0.9) !important;
}

.mdi-yellow.mdi-inactive {
    color: rgba(250, 196, 62, 0.26) !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-bottom___2f9G_-sass {
    height: 65px !important;
}

.react-horizontal-scrolling-menu--scroll-container {
    overflow-y: hidden;
    overflow-x: none;
}

.custom-img {
    width: 100px !important;
    height: auto !important;
}

.img_front_both_1 {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100% !important;
    height: 200px !important;
    position: absolute;
    z-index: -100;
}

.img_front_both_2 {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100% !important;
    height: 300px !important;
    position: absolute;
    z-index: -100;
}

.bg-img-capa {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-image: url('./img/pizza-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100% !important;
    height: 200px !important;
    position: absolute;
    z-index: -100;
}

.radio_custom {
    width: 24px !important;
    height: 24px !important;
}

.radio_custom_2 {
    width: 24px !important;
    height: 24px !important;
    margin-left: 30px !important;
}

.bg-dark-transparent {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-color: #161616;
    width: 100%;
    height: 200px;
    opacity: 60%;
    z-index: 100;
    position: absolute;
}

.pd-20 {
    padding-left: 20px;
    padding-right: 20px;

}

.pr-2 {
    padding-right: 20px !important;
}

.scroll-horizontal {
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    width: auto !important;
}

input {
    font-family: 'Gotham Light', sans-serif;
    font-size: 13px !important;
}

.cc_scroll {
    overflow-y: auto !important;
    overflow-x: auto !important;
}

select {
    font-family: 'Gotham Light', sans-serif;
    font-size: 13px !important;
}

.input-container {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    width: 95%;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 2px !important;
}

.icon {
    padding: 10px;
    background: #5d6071;
    color: white;
    min-width: 50px;
    text-align: center;
}

.input-field {
    width: 90% !important;
    padding: 10px;
    outline: none;
    background-color: #f0f0f0;
    border: none;
}

.text-title-tab-mob {
    width: 100%;
    padding: 10px;
    outline: none;
    text-align: center;
}

.scroll-none-menu {
    -ms-overflow-style: none !important;
    scrollbar-width: hidden !important;
    /* Hide vertical scrollbar */
    /* Hide horizontal scrollbar */
    height: auto !important;
    overflow: hidden !important;
}

.carousel .slide img {
    height: 400px !important;
}

.control-dots .dot {
    background: #0f0f10 !important;
}

.control-dots {
    padding-bottom: 20px !important;
}

.bg-dark-light {
    background-color: #474646 !important;
}

.bg-orange {
    background-color: #F2734C !important;
}

.bg-red-dark {
    background-color: #ac0519 !important;
}

.bg-dark-blue {
    background-color: #050246 !important;
}

.bg-dark-green {
    background-color: #056d01 !important;
}

@media only screen and (min-width: 768px) {
    /*.container-top-float {
        min-width: 100% !important;
        width: 100% !important;
        position: relative;
        top: -100% !important;
        background-color: white !important;
        height: auto !important;
        z-index: 200 !important;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        right: 0 !important;
        left: 0 !important;

    }*/

    .mp-select-content {
        width: 100px !important;
        height: auto !important;
    }

    .mp-text-content {
        width: 250px !important;
        height: auto !important;
    }

    .img_prod_list_type_1 {
        width: 100px !important;
        height: auto !important;
    }

    .box-profile {
        width: 70%;
        height: auto;
        background-color: white;
        position: relative;
        z-index: 200;
        margin-top: 5%;
        margin-left: 200px;
        margin-right: 200px;
        border-radius: 8px;
        padding-bottom: 20px;
    }

    .name-company {
        margin-top: 2%;
    }

    .name-company-pl {
        padding-left: 25px !important;
    }

    .box-img-logo {

        width: auto !important;
        height: auto !important;
        z-index: 40 !important;

        margin-top: -70px !important;
        position: absolute !important;
        border-radius: 8px !important;
        left: 0;
        right: 0;
    }

    .box-img-logo>img {
        width: 160px !important;
        height: 140px !important;
        padding: 5px !important;
        border-radius: 8px !important;
    }

    .container-box {
        margin-top: 14%;
    }


}

@media only screen and (max-width: 768px) {
    /*.container-top-float {
        min-width: 100% !important;
        width: 100% !important;
        position: relative;
        top: -100% !important;
        background-color: white !important;
        height: auto !important;
        z-index: 200 !important;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        right: 0 !important;
        left: 0 !important;
    }*/

    .mp-select-content {
        width: 100px !important;
        height: auto !important;
    }

    .mp-text-content {
        width: 250px !important;
        height: auto !important;
    }

    .img_prod_list_type_1 {
        width: 100px !important;
        height: auto !important;
    }

    .size-resp {
        width: 48px !important;
        height: 48px !important;
    }

    .carousel .slide img {
        height: 300px !important;
    }

    .name-company-pl {
        padding-left: 30px !important;
    }

    .box-profile {
        width: 100%;
        height: auto;
        background-color: white;
        position: relative;
        z-index: 200;
        margin-top: 5%;

        border-radius: 8px;
        padding-bottom: 20px;
    }

    .box-img-logo {
        background-color: #f0f0f0;
        width: auto;
        height: auto;
        z-index: 400;
        padding: 1px;
        margin-top: -35px;
        position: relative;


        border-radius: 8px;
    }

    .box-img-logo>img {
        width: 110px;
        height: 100px;
        border-radius: 8px;
    }

    .container-box {
        margin-top: 25%;
    }

    .container-box>box-profile {
        margin-left: 5%;
        margin-right: 5%;
    }

    .box-pattners {
        width: 100%;
        height: auto;
        background-color: white;
        position: relative;
        z-index: 200;
        border-radius: 8px;
        padding-bottom: 20px;
    }

}

/*.tab-head {
    padding-left: 0px !important;
    padding-right: 0px !important;
}*/
.border-non-left-input {
    border-color: #AA292E !important;
    border-bottom: 1px;
    border-right: 1px;
    border-left: 0 !important;
    border-top: 1px;
}

.border-non-right-input {
    border-color: #AA292E !important;
    border-bottom: 1px;
    border-left: 1px;
    border-right: 0;
    border-top: 1px;
    background-color: white;
}

.about-us {
    padding-left: 20px;
    padding-right: 20px;
}

.name-company>h2 {
    margin-left: 20px;
    margin-right: 40px;
}

.categories {
    float: left;
    position: relative;
}

.categories a:focus {
    outline: unset;

}

.categories a:hover {
    border: 1px solid #fff !important;
}

.categories a {
    color: black !important;
    font-weight: 600 !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.categories .active {
    color: black !important;
    border: none !important;
    /* border-bottom: 2px solid #ce0606 !important;*/
    font-weight: 600 !important;
}

.categories .active:hover {
    border: none !important;
    /* border-bottom: 2px solid #ce0606 !important;*/
}

.form-check-input:checked {
    background-color: black !important;
    border-color: black !important;
}

/*.tab-head {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.nav-item a:focus {
    outline: unset;
}

.nav-item a:hover {
    border: 1px solid #fff !important;
}

.nav-item a {
    color: black !important;
    font-weight: 600 !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.nav-item .active {
    color: black !important;
    border: none !important;
    border-bottom: 2px solid #ce0606 !important;
    font-weight: 600 !important;
}

.nav-item .active:hover {
    border: none !important;
    border-bottom: 2px solid #ce0606 !important;
}

.nav-tabs {
    border-bottom: none !important;
}

.tab-pane h5 {
    border-left: 4px solid #ce0606 !important;
}

.tab-pane p {
    border-top: 1px solid #c1cs1c1 !important;
}*/

.border-card-color {
    border: 0.5px solid #d2d1d1 !important;
}

.mob-bg-top-banner {
    width: 100% !important;
}

.mob-bg-top-banner-search {
    width: 100% !important;
}

.mob-tab-bar-top {
    height: 50px !important;
    width: 100% !important;
    background-color: white;
    z-index: 200;

}

.mob-tab-bar-top-inner {
    height: 60px !important;
    width: 100% !important;
    background-color: white;
    z-index: 200;
    top: 0;
    position: fixed;

}

.mob-tab-bar-top-inner-padding-top {
    padding-top: 60px !important;

}

.mob-tab-bar-top:nth-child(1) {
    box-shadow: 0 3px 3px rgb(0 0 0 / 0.2);

}

.mob-tab-bar-top-home {
    width: 100% !important;

}

.front-bar-float {
    z-index: 800 !important;
    position: fixed;
}

.mob-tab-bar-top-home-shadow {
    box-shadow: 0 1px 1px rgb(0 0 0 / 0.2);

}

.item-grid-size {
    width: 220px !important;
    height: 40px !important;

}

.category-menus-grid {
    border-bottom: none !important;
}

.category-menus-grid a:focus {
    outline: unset;
}

.category-menus-grid a:hover {
    border: none !important;
}

.category-menus-grid-item a:focus {
    outline: unset;

}

.category-menus-grid-item a:hover {
    border-bottom: 2px solid black !important;
}

.category-menus-grid-item a {
    color: black !important;
    font-weight: 600 !important;


}

.category-menus-grid-item .active {
    color: black !important;
    border: none !important;
    border-bottom: 2px solid #ce0606 !important;
    font-weight: 600 !important;
}

.category-menus-grid-item .active:hover {
    border: none !important;
    border-bottom: 2px solid #ce0606 !important;
}

/*.nav {
    top: -20px !important;
    margin: 0 !important;
    transition: all 0.1s linear;
    width: auto !important;
    position: fixed;
    z-index: 2000;
    padding: 20px;
}*/
.scrollNav {
    transition: all 0.5s ease-in;
    z-index: 2000;
    background: #ffffff;

    border-bottom: 1px solid #dddddd;
}

.top-bar-nav-100 {
    position: absolute;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 1;
    height: 100px !important;
    border-bottom: 1px solid #ebebeb;
}

.top-bar-nav-160 {
    position: fixed;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 1;
    height: 160px !important;
    border-bottom: 1px solid #ebebeb;
}

.align-bottom-custom {
    bottom: 0 !important;
}

.align-bottom-custom2 {
    bottom: 0 !important;
    margin-top: 50% !important;
}

.btn-size {
    width: 100% !important;
}

.react-scroll-mob {
    overflow-y: scroll;
    overflow-x: hidden;
}

.carousel-control-prev-icon {
    display: none !important;
}

.carousel-control-next-icon {
    display: none !important;
}

.carousel-indicators {
    display: none !important;
}