.c_ribbon {
    line-height: 0.8em;
    font-size: 1.8em;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    letter-spacing: -2px;
    display: block;
    width: 4.5rem;
    height: 3.2rem;
    background: linear-gradient(to bottom, #999999 0%, #cccccc 100%);
    color: white;
    
    float: right;
    padding-top: 0.5rem;
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
    transform: translate3d(0, 0, 0);
}

.c_ribbon:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 2.4rem solid transparent;
    border-left: 2rem solid transparent;
    border-top: 2rem solid #d3362d;
    position: fixed;
    top: 3.2rem;
    right: 0;
}

.c_ribbon.c_ribbon--red {
    background: linear-gradient(to bottom, #d3362d 0%, #e57368 100%);
}

.c_ribbon.c_ribbon--red:after {
    border-top: 1.5rem solid #E57368;
    
}